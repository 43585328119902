import React from "react";
import { Route } from "react-router-dom";
import Responsive from "./components/common/Responsive";
import MainPageContainer from "./container/MainPageContainer";
import QuizPageContainer from "./container/QuizPageContainer";
import ResultPageContainer from "./container/ResultPageContainer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Responsive>
        <Route component={MainPageContainer} path={["/"]} exact />
        <Route component={QuizPageContainer} path={["/quiz"]} exact />
        <Route
          component={ResultPageContainer}
          path={["/result", "/result/:mbti"]}
          exact
        />
      </Responsive>
    </>
  );
}

export default App;
