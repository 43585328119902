import React, { useState } from "react";
import Button from "../components/common/Button";
import ProgressBar from "../components/common/ProgressBar";

import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";

const QuizPage = ({ quizList, getAnswer, loading, error }) => {
  const [step, setStep] = useState(0);
  const [checkList, setCheckList] = useState([]);
  const checkPrevStrong = (text) => {
    return text.substring(0, text.indexOf("*"));
  };
  const checkStrong = (text) => {
    const tmpIndex = text.indexOf("*");
    return text.substring(tmpIndex + 1, text.indexOf("*", tmpIndex + 1));
  };
  const checkNextStrong = (text) => {
    const tmpIndex = text.indexOf("*");
    return text.substring(text.indexOf("*", tmpIndex + 1) + 1);
  };
  const preStrongBreak = (text) => {
    const tmpIndex = text.indexOf("*");
    text
      .substring(tmpIndex + 1, text.indexOf("*", tmpIndex + 1))
      .substring(
        0,
        text
          .substring(tmpIndex + 1, text.indexOf("*", tmpIndex + 1))
          .indexOf("/")
      );
  };
  const nextStrongBreak = (text) => {
    const tmpIndex = text.indexOf("*");

    text
      .substring(tmpIndex + 1, text.indexOf("*", tmpIndex + 1))
      .substring(
        text
          .substring(tmpIndex + 1, text.indexOf("*", tmpIndex + 1))
          .indexOf("/") + 1
      );
  };
  return loading || typeof loading === "undefined" ? (
    <QuizContainer>
      {/* <Helmet>
        <title>빌보드 테스트</title>
        <meta name="title" content="빌보드 테스트" />
        <meta
          name="keywords"
          content="빌보드, Universal, Universal Music Korea, 테스트, 팝스타"
        />
        <meta
          name="description"
          content="당신 속 숨어있는 빌보드 스타는 누구??"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ko_KR" />
        <meta property="og:url" content="https://www.billboardtest.co.kr/" />
        <meta
          property="og:image"
          content="https://api.billboardtest.co.kr/static/main_image.png"
        />
        <meta property="og:title" content="빌보드 테스트" />
        <meta
          property="og:description"
          content="당신 속 숨어있는 빌보드 스타는 누구??"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="빌보드 테스트" />
        <meta name="twitter:url" content="https://www.billboardtest.co.kr/" />
        <meta
          name="twitter:image"
          content="https://api.billboardtest.co.kr/static/main_image.png"
        />
        <meta
          name="twitter:description"
          content="당신 속 숨어있는 빌보드 스타는 누구??"
        />
     
      </Helmet> */}
      퀴즈 목록을 받아오는 중입니다...
    </QuizContainer>
  ) : (
    <>
      <Helmet title={"빌보드 테스트 - 퀴즈"} />

      <QuizContainer>
        <QuizContent key={quizList[step].title.substring(3)}>
          <QuizNumber>
            Q
            {quizList[step].title.substring(0, 2).substring(1) === "."
              ? quizList[step].title.substring(0, 1)
              : quizList[step].title.substring(0, 2)}
          </QuizNumber>
          <QuizDescription>
            {checkPrevStrong(quizList[step].title.substring(3)).indexOf("/") !==
              -1 &&
              checkPrevStrong(quizList[step].title.substring(3))
                .split("/")
                .map((line) => {
                  return <span>{line}</span>;
                })}
            {checkPrevStrong(quizList[step].title.substring(3)).indexOf("/") ===
              -1 && checkPrevStrong(quizList[step].title.substring(3))}
            <strong>
              {checkStrong(quizList[step].title.substring(3)).indexOf("/") !==
                -1 &&
                checkStrong(quizList[step].title.substring(3))
                  .split("/")
                  .map((line) => {
                    return <span>{line}</span>;
                  })}

              {checkStrong(quizList[step].title.substring(3)).indexOf("/") ===
                -1 && checkStrong(quizList[step].title.substring(3))}
            </strong>
            {checkNextStrong(quizList[step].title.substring(3)).indexOf("/") !==
              -1 &&
              checkNextStrong(quizList[step].title.substring(3))
                .split("/")
                .map((line) => {
                  return <span>{line}</span>;
                })}
            {checkNextStrong(quizList[step].title.substring(3)).indexOf("/") ===
              -1 && checkNextStrong(quizList[step].title.substring(3))}
          </QuizDescription>
          <>
            <QuizButton
              to={step === 11 ? "/result" : 0}
              onClick={() => {
                step === 11
                  ? getAnswer(checkList.concat(["A"]))
                  : setCheckList(checkList.concat(["A"]));
                setStep(step + 1);
              }}
              fullwidth
              custom={step}
            >
              {checkPrevStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "A")[0]
                  .substring(3)
              ).indexOf("/") !== -1 &&
                checkPrevStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "A")[0]
                    .substring(3)
                )
                  .split("/")
                  .map((line) => {
                    return <span>{line}</span>;
                  })}
              {checkPrevStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "A")[0]
                  .substring(3)
              ).indexOf("/") === -1 &&
                checkPrevStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "A")[0]
                    .substring(3)
                )}
              <strong>
                {checkStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "A")[0]
                    .substring(3)
                ).indexOf("/") !== -1 &&
                  checkStrong(
                    quizList[step].answers
                      .filter((item) => item.substring(0, 1) === "A")[0]
                      .substring(3)
                  )
                    .split("/")
                    .map((line) => {
                      return <span>{line}</span>;
                    })}

                {checkStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "A")[0]
                    .substring(3)
                ).indexOf("/") === -1 &&
                  checkStrong(
                    quizList[step].answers
                      .filter((item) => item.substring(0, 1) === "A")[0]
                      .substring(3)
                  )}
              </strong>
              {checkNextStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "A")[0]
                  .substring(3)
              ).indexOf("/") !== -1 &&
                checkNextStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "A")[0]
                    .substring(3)
                )
                  .split("/")
                  .map((line) => {
                    return <span>{line}</span>;
                  })}
              {checkNextStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "A")[0]
                  .substring(3)
              ).indexOf("/") === -1 &&
                checkNextStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "A")[0]
                    .substring(3)
                )}
            </QuizButton>

            <QuizButton
              to={step === 11 ? "/result" : 0}
              onClick={() => {
                step === 11
                  ? getAnswer(checkList.concat(["B"]))
                  : setCheckList(checkList.concat(["B"]));
                setStep(step + 1);
              }}
              fullwidth
            >
              {checkPrevStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "B")[0]
                  .substring(3)
              ).indexOf("/") !== -1 &&
                checkPrevStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "B")[0]
                    .substring(3)
                )
                  .split("/")
                  .map((line) => {
                    return <span>{line}</span>;
                  })}
              {checkPrevStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "B")[0]
                  .substring(3)
              ).indexOf("/") === -1 &&
                checkPrevStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "B")[0]
                    .substring(3)
                )}
              <strong>
                {checkStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "B")[0]
                    .substring(3)
                ).indexOf("/") !== -1 &&
                  checkStrong(
                    quizList[step].answers
                      .filter((item) => item.substring(0, 1) === "B")[0]
                      .substring(3)
                  )
                    .split("/")
                    .map((line) => {
                      return <span>{line}</span>;
                    })}

                {checkStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "B")[0]
                    .substring(3)
                ).indexOf("/") === -1 &&
                  checkStrong(
                    quizList[step].answers
                      .filter((item) => item.substring(0, 1) === "B")[0]
                      .substring(3)
                  )}
              </strong>
              {checkNextStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "B")[0]
                  .substring(3)
              ).indexOf("/") !== -1 &&
                checkNextStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "B")[0]
                    .substring(3)
                )
                  .split("/")
                  .map((line) => {
                    return <span>{line}</span>;
                  })}
              {checkNextStrong(
                quizList[step].answers
                  .filter((item) => item.substring(0, 1) === "B")[0]
                  .substring(3)
              ).indexOf("/") === -1 &&
                checkNextStrong(
                  quizList[step].answers
                    .filter((item) => item.substring(0, 1) === "B")[0]
                    .substring(3)
                )}
            </QuizButton>
          </>
          <QuizProgress>
            <QuizStep>
              {step + 1} / {quizList.length}
            </QuizStep>
            <ProgressBar pos={((step + 1) * 100) / quizList.length} />
          </QuizProgress>
        </QuizContent>
      </QuizContainer>
    </>
  );
};

export default QuizPage;
const QuizContainer = styled.div`
  margin: 50px auto;
  position: relative;
  max-width: 315px;
`;

const QuizContent = styled.div`
  width: 100%;
  height: 100%;
`;

const QuizNumber = styled.span`
  letter-spacing: -1.5px;
  color: #0eefff;
  opacity: 1;

  font-size: 50px;
  line-height: 19px;

  font-family: "GmarketB";
  text-align: center;
`;

const QuizDescription = styled.div`
  margin-top: 42px;
  margin-bottom: 65px;
  height: 121px;
  text-align: left;
  font: normal normal normal 32px/43px NanumSquareOTF;
  letter-spacing: -1.6px;
  color: #ffffff;
  font-size: 32px;
  line-height: 43px;
  /* word-break: keep-all; */

  & > strong {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: -1.6px;
    color: #0eefff;
    @media (min-width: 768px) {
      font-size: 29px;
    }
  }
  & span + span::before {
    content: "";
    display: block;
  }
  @media (min-width: 768px) {
    font-size: 29px;
  }
`;

const QuizButton = styled(Button)`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */

  font-family: "NanumSquareR";
  height: 110px;
  /* word-break: keep-all; */

  & + & {
    margin-top: 39px;
  }

  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 25px;
  text-align: center;
  /* word-break: keep-all; */

  letter-spacing: -1.1px;
  color: #191619;
  opacity: 1;
  font-size: 20px;
  line-height: 31px;
  & > strong {
    letter-spacing: -1.1px;
    color: #191619;
    font-weight: 800;
    font-size: 20px;
    line-height: 31px;
  }
  & span + span::before {
    content: "";
    display: block;
  }
`;

const QuizProgress = styled.div`
  margin-top: 75px;
`;

const QuizStep = styled.div`
  text-align: right;
  font-size: 15px;
  line-height: 22px;

  letter-spacing: -0.6px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 8px;
`;
