import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ResultPage from "../pages/ResultPage";

// import { getMbti } from "../lib/api/result";
import { mbtiToStar, clearnStar } from "../modules/result";
import { kakaoInit, kakaoScript } from "../lib/kakaoScript";
import { iOS } from "../lib/checkIOS";

const ResultPageContainer = ({ match }) => {
  const {
    resultStar,
    loading,
    error,
    subscribe,
    subscribe_result,
  } = useSelector(({ result, loading }) => ({
    loading: loading["result/SENDANSWER"]
      ? loading["result/SENDANSWER"]
      : loading["result/MBTITOSTAR"],
    resultStar: result.resultStar,
    error: result.error,
    subscribe: result.subscribe,
    subscribe_result: result.subscribe_result,
  }));
  // 이아래는 params로 우리의 page조회할수 있게 해주는 부분
  // const [mbtiStar, setMbtiStar] = useState();
  const { mbti } = match.params;
  const [showShare, setShowShare] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (mbti !== undefined) {
      dispatch(mbtiToStar(mbti));
    }
    kakaoScript();
    let flag = false;

    window.addEventListener("scroll", function (e) {
      // console.log(this.pageYOffset);
      if (this.pageYOffset > 100 && flag === false) {
        flag = true;
        setShowShare(true);
      }

      if (this.pageYOffset <= 100 && flag === true) {
        flag = false;
        setShowShare(false);
      }
    });

    return () => dispatch(clearnStar());
  }, [dispatch, mbti]);

  kakaoInit();

  return (
    <ResultPage
      resultStar={resultStar}
      loading={loading}
      error={error}
      subscribe={subscribe}
      subscribe_result={subscribe_result}
      mbti={mbti} //플래그로 사용할 mbti입니다 (이때는 로딩해주지 않기위한 플래그)
      showShare={showShare}
    />
  );
};

export default ResultPageContainer;
