import client, { BASE_URL } from "./client";

export const sendApi = (answerList) =>
  client.post(BASE_URL + "get_result/", {
    answers: answerList.toString(),
  });

export const getMbti = (mbti) => client.get(BASE_URL + `get_result/${mbti}`);

export const sendSubscribe = ({ username, email }) => {
  const subscribeForm = new FormData();
  subscribeForm.append("name", username);
  subscribeForm.append("email", email);
  return client.post(BASE_URL + "subscribe/", subscribeForm);
};
