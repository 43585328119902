import React, { useState } from "react";
import Button from "../common/Button";
import { useDispatch } from "react-redux";
import { changeField, sendSubscribe } from "../../modules/result";
import styled, { css } from "styled-components";
import mainIcon from "../../asset/images/letter_icon.png";

const StyledFormBlock = styled.form`
  overflow: hidden;
  margin: 0 auto;
  max-width: 475px;
  width: 100%;

  height: 0px;
  opacity: 0;
  transition: all 0.3s ease-out;
  padding-top: 25px;

  ${(props) =>
    props.display &&
    css`
      height: 250px;
      opacity: 1;

      max-height: max-content;
      transition: all 0.3s ease-out;
    `};
`;

const SubScribeForm = ({ subscribe, display }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [showComplete, setShowComplete] = useState(false);
  if (showComplete) {
    setTimeout(() => setShowComplete(false), 1200);
  }
  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
        form: "subscribe",
        key: name,
        value,
      })
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { username, email } = subscribe;
    if ([username, email].includes("")) {
      setError("빈 칸 모두 입력해주세요!");
      // 만약 빈칸이있으면 focusing해줘도 좋을듯
      return;
    }
    if (!email.includes("@")) {
      setError("이메일 형식을 지켜주세요!");
      // 이메일을 지워주고 다시보여줄 것인지
      return;
    }
    dispatch(sendSubscribe({ username, email }));
    setShowComplete(true);
    dispatch(
      changeField({
        form: "subscribe",
        key: "username",
        value: "",
      })
    );
    dispatch(
      changeField({
        form: "subscribe",
        key: "email",
        value: "",
      })
    );
  };

  return (
    <StyledFormBlock
      display={display}
      method="POST"
      action="https://api.billboardtest.co.kr/subscribe"
      onSubmit={onSubmit}
    >
      <StyledFormInput
        autoComplete="username"
        name="username"
        placeholder="이름"
        onChange={onChange}
        value={subscribe.username}
      />
      <StyledFormInput
        autoComplete="email"
        name="email"
        placeholder="이메일"
        onChange={onChange}
        value={subscribe.email}
      />
      {error && <StyledFormError>{error}</StyledFormError>}
      <StyledFormButton fullwidth>
        <img src={mainIcon} alt="mainIcon" /> 메일 구독하기
      </StyledFormButton>
      {showComplete && (
        <CompleteBlock> 🎁 구독이 완료되었습니다 🎁 </CompleteBlock>
      )}
    </StyledFormBlock>
  );
};

export default SubScribeForm;

const StyledFormInput = styled.input`
  border: none;
  background: none;
  outline: none;

  width: 100%;

  padding: 10px;

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0eefff;
  border-radius: 5px;
  opacity: 1;
  font-size: 18px;

  letter-spacing: -0.54px;
  color: #3c3e44;
  opacity: 0.4;

  & + & {
    margin-top: 15px;
  }
`;

const StyledFormButton = styled(Button)`
  margin-top: 35px;
  padding: 10px;
  background-color: #191619;

  font-size: 27px;
  font-weight: 800;
  letter-spacing: -0.9px;
  color: #ffffff;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 38px;
    height: 23px;

    margin-right: 7px;
  }
`;

const StyledFormError = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  color: #df5858;
  font-size: 1.3rem;
  font-weight: bold;
`;

const CompleteBlock = styled.div`
  @keyframes smoothModal {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    40% {
      opacity: 0.7;
    }
    60% {
      opacity: 0.4;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }
  font-size: 1.5rem;
  background-color: #3c3e44;
  position: fixed;
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 15px;
  left: calc(50% - 150px);
  top: calc(50% - 50px);
  animation: smoothModal 1.3s linear;
`;
