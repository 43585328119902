import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import kakao from "../../asset/images/kakao.png";
import link from "../../asset/images/link.png";

const ShareButtonStyle = css`
  position: fixed;
  right: calc(50vw - 187.5px);
  border: none;
  background: none;
  top: 450px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;

  outline: none;
  padding: 0;

  @media (max-width: 375px) {
    right: 12px;
  }
  & > img {
    width: 100%;
  }
`;

const NormalShare = styled.button`
  ${ShareButtonStyle}
  top: 505px;
`;

const KakaoShare = styled.button`
  ${ShareButtonStyle}
`;

const ShareButton = ({ type, star, children }) => {
  const hiddenInput = useRef(null);
  const [linkcopy, setLinkCopy] = useState(false);

  if (linkcopy) {
    setTimeout(() => setLinkCopy(false), 1200);
  }
  const sendKakaoLink = (star) => {
    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "나와 닮은 빌보드 스타는?",
        description:
          "나는 " +
          star.name +
          ", " +
          star.sub_title +
          "😎" +
          "\n" +
          "너도 해보지 않을래?", //최대 4줄
        imageUrl: "https://api.billboardtest.co.kr/" + star.share_image,
        // image_width.image_height
        link: {
          mobileWebUrl: "https://www.billboardtest.co.kr/result/" + star.mbti,
          webUrl: "https://www.billboardtest.co.kr/result/" + star.mbti, //PC버전 카톡에서 지원하는 웹링크 URL(도메인은 개발자 사이트에 등록된 도메인과 일치)
        },
      },
      // social: {
      //   likeCount: 286,
      //   commentCount: 45,
      //   sharedCount: 845, 이부분은 우리가 체크해서 볼수 있게 하자
      //   viewCount, subscriberCount
      // },
      buttons: [
        // {
        //   title: "소식보기",
        //   link: {
        //     mobileWebUrl:
        //       "https://www.youtube.com/channel/UCTv63hdYuhVyORy6r4HdEEw",
        //     webUrl: "https://www.youtube.com/channel/UCTv63hdYuhVyORy6r4HdEEw",
        //   },
        // },
        {
          title: "테스트하기",
          link: {
            mobileWebUrl: "https://www.billboardtest.co.kr/",
            webUrl: "https://www.billboardtest.co.kr/",
          },
        },
      ],
      serverCallbackArgs: {
        // 콜백 파라미터 설정
        key: process.env.REACT_APP_KAKAO_ADMIN,
      },

      // callback: 여기에 콜백함수 작성해줄수 있음(IE제외),
      // serverCallbackArgs https://developers.kakao.com/docs/latest/ko/message/js#set-kakaolink-callback\
      // https://developers.kakao.com/docs/latest/ko/message/rest-api#set-kakaolink-callback 이부분 설정 잘하면 어느 방에 공유했는지 알수 있음.
    });
  };

  const urlShare = (star) => {
    if (navigator.share) {
      navigator
        .share({
          title: "나와 닮은 빌보드 스타는?",
          url: "https://www.billboardtest.co.kr/result/" + star.mbti,
        })
        .then(() => {
          console.log("30bpm! go 3000! go 3000!");
        })
        .catch(console.error);
    } else {
      // hiddenInput.current.value = window.document.location.href;
      hiddenInput.current.value =
        "https://www.billboardtest.co.kr/result/" + star.mbti;
      hiddenInput.current.select();
      document.execCommand("copy");
      // hiddenInput.current.blur(); //포커싱된 값을 지우는 method
      setLinkCopy(true);
      console.log("30bpm! go 3000! go 3000!");
    }
  };
  //일반 공유는 링크를 공유한다.
  if (type === "default") {
    return (
      <>
        <NormalShare onClick={() => urlShare(star)}>
          <img src={link} alt="link " />

          {children}
          <HiddenInputBox readOnly type="text" value="" ref={hiddenInput} />
        </NormalShare>
        {linkcopy && (
          <LinkCopyDisplay>💡 링크가 복사되었습니다.</LinkCopyDisplay>
        )}
      </>
    );
  }
  if (type === "kakao") {
    return (
      <KakaoShare onClick={() => sendKakaoLink(star)}>
        <img src={kakao} alt="kakao" />

        {children}
      </KakaoShare>
    );
  }
  return null;
};

export default ShareButton;

const HiddenInputBox = styled.input`
  /* display: none; */
  /* visibility: hidden; */
  /* 히든 되어있는 엘리먼트는 선택할수 없다. */
  opacity: 0;
  cursor: default;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 5px;
  height: 0;
  padding: 0;
  margin: 0;
`;

const LinkCopyDisplay = styled.div`
  @keyframes smoothModal {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    40% {
      opacity: 0.7;
    }
    60% {
      opacity: 0.4;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }
  font-size: 1.5rem;
  background-color: #3c3e44;
  position: fixed;
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 15px;
  left: calc(50% - 150px);
  top: calc(50% - 50px);
  animation: smoothModal 1.3s linear;
`;
