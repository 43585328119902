import { createAction, handleActions } from "redux-actions";
import produce from "immer";

// Saga를 만드는 부분을 따로 lib로 만들어서 빼서 관리한다.
import createRequestSaga, {
  createRequestActionTypes,
  createDelayRequestSaga,
} from "../lib/createRequestSaga";

import { takeLatest } from "redux-saga/effects";

import * as resultApi from "../lib/api/result";

const [
  SENDANSWER,
  SENDANSWER_SUCCESS,
  SENDANSWER_FAILURE,
] = createRequestActionTypes("result/SENDANSWER");
const CHANGEFIELD = "result/CHANGEFIELD";

const [
  MBTITOSTAR,
  MBTITOSTAR_SUCCESS,
  MBTITOSTAR_FAILURE,
] = createRequestActionTypes("result/MBTITOSTAR");

const [
  SUBSCRIBE,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE,
] = createRequestActionTypes("result/SUBSCRIBE");

// 액션 생성

const CLEANSTAR = "result/CLEANSTAR";

export const sendAnswer = createAction(SENDANSWER);
export const changeField = createAction(
  CHANGEFIELD,
  ({ form, key, value }) => ({ form, key, value })
);
export const mbtiToStar = createAction(MBTITOSTAR);
export const sendSubscribe = createAction(SUBSCRIBE);
export const clearnStar = createAction(CLEANSTAR);
// 리퀘스트 사가 생성
const sendAnswerSaga = createDelayRequestSaga(SENDANSWER, resultApi.sendApi);

const mbtiToStarSaga = createRequestSaga(MBTITOSTAR, resultApi.getMbti);

const subscribeSaga = createRequestSaga(SUBSCRIBE, resultApi.sendSubscribe);

export function* resultSaga() {
  yield takeLatest(SENDANSWER, sendAnswerSaga);
  yield takeLatest(MBTITOSTAR, mbtiToStarSaga);
  yield takeLatest(SUBSCRIBE, subscribeSaga);
}

const initialState = {
  resultStar: null,
  error: null,
  subscribe: {
    username: "",
    email: "",
  },
  subscribe_result: null,
  mbtiStar: null,
};

const result = handleActions(
  {
    [SENDANSWER_SUCCESS]: (state, { payload: payload }) => {
      return {
        ...state,
        resultStar: payload.star,
      };
    },

    [SENDANSWER_FAILURE]: (state, { payload: error }) => {
      return {
        ...state,
        error,
      };
    },

    [MBTITOSTAR_SUCCESS]: (state, { payload: payload }) => {
      return {
        ...state,
        resultStar: payload.star,
      };
    },
    [MBTITOSTAR_FAILURE]: (state, { payload: error }) => {
      return {
        ...state,
        error,
      };
    },

    [CHANGEFIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [SUBSCRIBE_SUCCESS]: (state, { payload: payload }) => {
      return {
        ...state,
        subscribe_result: "구독해주셔서 감사합니다😎",
      };
    },
    [SUBSCRIBE_FAILURE]: (state, { payload: error }) => {
      return {
        ...state,
        error: "다시 시도해주세요😥",
      };
    },
    [CLEANSTAR]: (state, payload) => {
      return {
        ...state,
        resultStar: null,
      };
    },
  },
  initialState
);

export default result;
