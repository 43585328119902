import React from "react";
import styled from "styled-components";

const ResponsiveBlock = styled.div`
  /* 기본적으로 device 와 1rem만큼의 padding을 가지게 함 */
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */
  /* width: 1024px; */

  /* 퀴즈의 경우 최대 넓이도 어느정도 모바일에 맞추어 제작하게 될 예정이므로 768px로 맞춤 */
  /* width: 768px; */

  /* margin: 0 auto; */
  min-height: 100vh;

  /* border: 2px solid blue; */

  /* 미디어쿼리 */
  /* @media (max-width: 1024px) {
    width: 768px;
  }
  @media (max-width: 768px) {
    width: 100%;
  } */

  /* 여기는 umk용 레이아웃 잡기 */
  /* 아래는 임시코드입니다. */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & header {
    font-size: 48px;
    margin-bottom: 40px;
  }
  & b {
    display: block;
    font-size: 30px;
    margin-bottom: 20px;
  }
  & p {
    font-weight: 800;
    font-size: 30px;
    color: #020008;
  } */
`;

const Responsive = ({ children, ...rest }) => {
  //style, className, conClick , onMouseMove 등의 props를 사용할수 있도록
  //...rest를사용해서 ReponsiveBlock에 전달

  return <ResponsiveBlock {...rest}>{children}</ResponsiveBlock>;
};

export default Responsive;
