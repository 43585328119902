import React from "react";
import styled from "styled-components";

const ProgressBlock = styled.div`
  width: 100%;
  height: 10px;
  position: relative;

  & > div {
    position: absolute;
    left: 0;
    top: 2px;
    width: 100%;
    height: 5px;
    background: #3c3e44 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  & > span {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: ${(props) => props.pos}%;
    height: 9px;
    z-index: 1;

    background: #0eefff 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
  }
`;

const ProgressBar = ({ children, ...rest }) => {
  return (
    <ProgressBlock {...rest}>
      <span></span>
      <div></div>
    </ProgressBlock>
  );
};

export default ProgressBar;
