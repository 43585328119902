import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuiz } from "../modules/quiz";
import QuizPage from "../pages/QuizPage";
import { sendAnswer } from "../modules/result";
const QuizPageContainer = () => {
  const dispatch = useDispatch();
  const { quizList, loading, error } = useSelector(({ quiz, loading }) => ({
    quizList: quiz.quizList,
    loading: loading["quiz/GETQUIZ"],
    error: quiz.error,
  }));
  useEffect(() => {
    dispatch(getQuiz("디용"));
    return () => {};
  }, [dispatch]);

  const getAnswer = (answers) => {
    dispatch(sendAnswer(answers));
  };

  return (
    <QuizPage
      quizList={quizList}
      loading={loading}
      error={error}
      getAnswer={getAnswer}
    />
  );
};

export default QuizPageContainer;
