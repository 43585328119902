import { call, put, delay } from "redux-saga/effects";
import { startLoading, finishLoading } from "../modules/loading";

// Type을 만들어 주는애
export const createRequestActionTypes = (type) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return [type, SUCCESS, FAILURE];
};

// action을 만들어 주는 애

export default function createRequestSaga(type, request) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* (action) {
    yield put(startLoading(type)); //로딩시작
    try {
      const response = yield call(request, action.payload);
      // yield delay(5000); 이렇게 딜레이를 설정하면 좋은데 공통적으로 사용하는 hook? 이므로 그냥 실행자체를 따로해줘야겠다.
      yield put({
        type: SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      yield put({
        type: FAILURE,
        payload: e,
        error: true,
      });
    }
    yield put(finishLoading(type)); //로딩 끝
  };
}

export function createDelayRequestSaga(type, request) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* (action) {
    yield put(startLoading(type)); //로딩시작
    try {
      const response = yield call(request, action.payload);
      yield delay(3000);
      yield put({
        type: SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      yield put({
        type: FAILURE,
        payload: e,
        error: true,
      });
    }
    yield put(finishLoading(type)); //로딩 끝
  };
}
