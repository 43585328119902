import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const BtnStyle = css`
  border: none;
  background: none;

  cursor: pointer;
  padding: 15px;
  font-size: 1.2rem;
  background-color: #ffffff;
  color: #191619;
  border-radius: 15px;
  /* margin: 15px; */
  outline: none;
`;
const StyledButton = styled.button`
  ${BtnStyle}
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;
const StyledLink = styled(Link)`
  display: inline-block;
  ${BtnStyle}
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;
const Button = (props) => {
  return props.to ? <StyledLink {...props} /> : <StyledButton {...props} />;
};

export default Button;
