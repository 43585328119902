import { createAction, handleActions } from "redux-actions";

// Saga를 만드는 부분을 따로 lib로 만들어서 빼서 관리한다.
import createRequestSaga, {
  createRequestActionTypes,
} from "../lib/createRequestSaga";

// saga와 관련된 함수
import { takeLatest } from "redux-saga/effects";

import * as introApi from "../lib/api/intro";

const [GETINTRO, GETINTRO_SUCCESS, GETINTRO_FAILURE] = createRequestActionTypes(
  "intro/GETINTRO"
);

export const getIntro = createAction(GETINTRO);
const getIntroSaga = createRequestSaga(GETINTRO, introApi.get);

export function* introSaga() {
  yield takeLatest(GETINTRO, getIntroSaga);
}
const initialState = {
  error: null,
  total_cnt: 0,
  top_three_star: null,
};

const intro = handleActions(
  {
    [GETINTRO_SUCCESS]: (state, payload) => {
      const IntroInfo = payload.payload;
      // console.log(payload.payload.top_stars);
      // const sortByCnt = (a, b) => {
      //   if (a.count < b.count) {
      //     return -1;
      //   }
      //   if (a.count > b.count) {
      //     return 1;
      //   }
      //   return 0;
      // };

      // IntroInfo.top_stars.sort(sortByCnt);
      // console.log(IntroInfo.top_stars);
      return {
        ...state,
        total_cnt: IntroInfo.all_count,
        top_three_star: IntroInfo.top_stars.slice(0, 3),
      };
    },
    [GETINTRO_FAILURE]: (state, { payload: error }) => {
      return {
        ...state,
        error,
      };
    },
  },
  initialState
);

export default intro;
