import React from "react";
import Button from "../components/common/Button";
import styled, { css } from "styled-components";
import mainbg from "../asset/images/main_image.png";
import bottomlogo from "../asset/images/umk logo.png";
import { Helmet } from "react-helmet";
const MainPage = ({ total_cnt, loading, error, top_three_star, showCard }) => {
  return loading || typeof loading === "undefined" ? (
    <MainContainer>{null}</MainContainer>
  ) : (
    <>
      <MainContainer>
        <MainTitle>
          나와 닮은 <br />
          <b>
            빌보드
            <br /> 스타<span>는?</span>
          </b>
        </MainTitle>
        <MainBgImageBox></MainBgImageBox>
        <MainStartButton to="/quiz">
          테스트 <b>START</b>
        </MainStartButton>
        <MainBottomLogo src={bottomlogo} alt="umk_logo" />
        <MainBottomBg>
          <MainBottomCnt>
            지금까지 나와 닮은 스타를 만난 사람
            <br />
            <div>{total_cnt.toLocaleString("ko")}</div> 명
          </MainBottomCnt>

          <MainBottomList showCard={showCard}>
            <header>가장 많은 스타 유형은?</header>
            {top_three_star.map((star, index) => (
              <MainBottomCard key={star.name} showCard={showCard}>
                <span>{index + 1}위</span>
                <img
                  src={"https://api.billboardtest.co.kr" + star.image}
                  alt={star.name}
                />
                <div>
                  {star.sub_title}
                  <br />
                  <b>{star.name}</b>
                </div>
              </MainBottomCard>
            ))}
          </MainBottomList>
          <MainBottomHelpText>
            참여자 참여 통계는 실시간 마다 갱신됩니다.
          </MainBottomHelpText>
        </MainBottomBg>
        <MainFooter>
          <b>문의 : contact@umusic.com</b>
          <span>COPYRIGHT(C) UNIVERSAL MUSIC GROUP. ALL RIGHTS RESERVED.</span>
        </MainFooter>
      </MainContainer>
    </>
  );
};

export default MainPage;

const MainContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  margin-top: 64px;
`;

const MainTitle = styled.header`
  /* color: #3c3e44; */
  text-align: center;
  font-weight: 300;
  font-size: 39px;
  line-height: 57px;
  letter-spacing: -1.95px;
  color: #ffffff;
  opacity: 1;
  font-family: "GmarketL";

  & > b {
    font-family: "GmarketB";
    font-size: 57px;
    line-height: 57px;
    font-weight: bold;
    text-align: center;
    letter-spacing: -2.28px;
    color: #0eefff;
  }
  & > b > span {
    font-size: 40px;
    line-height: 57px;
    font-weight: bold;

    text-align: center;
    letter-spacing: -1.6px;
    color: #ffffff;
  }
`;
const MainBgImageBox = styled.div`
  background: transparent url(${mainbg}) 0% 0% no-repeat padding-box;
  background-position: center;
  background-position-x: -80px;
  background-size: cover;
  opacity: 0.71;
  height: 500px;
  margin: 0 auto;
  max-width: 375px;
  margin-top: -10px;
  width: 100%;
  z-index: -1;

  @media (min-width: 375px) {
    background-position: bottom;

    background-size: 120%;
    background-position-x: -40px;
  }
`;
// const MainBgImage = styled.img`
//   max-width: 475px;
//   width: 100%;
//   display: block;
//   margin: 0 auto;
//   z-index: -1;
//   background: transparent 0% 0% no-repeat padding-box;
//   opacity: 0.71;
// `;

const MainStartButton = styled(Button)`
  /* position: absolute;
  left: calc(50% - 237.5px);
  top: 450px; */
  display: block;

  /*  */
  font-family: "NanumSquareR";
  font-size: 34px;
  line-height: 34px;

  text-align: center;
  letter-spacing: -1.7px;
  color: #191619;
  width: 315px;
  padding: 11px 55px 7px 55px;
  position: absolute;
  left: calc(50% - 157.5px);
  top: 500px;
  background: #0eefff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  z-index: 5;
  & > b {
    font-family: "NanumSquareEB";
    font-weight: 800;
  }
`;

const MainBottomLogo = styled.img`
  width: 116px;
  height: 43px;
  display: block;
  position: absolute;

  top: 575px;
  left: calc(50% - 50px);
`;
const MainBottomBg = styled.div`
  background-color: #414250;
`;
const MainBottomCnt = styled.div`
  letter-spacing: -0.6px;
  color: #ffffff;
  opacity: 1;

  font-size: 20px;
  line-height: 25px;
  padding-top: 44px;
  text-align: center;
  & > div {
    letter-spacing: -0.7px;
    color: #ffffff;
    opacity: 1;
    font-size: 35px;
    line-height: 29px;
    margin-top: 13px;
    display: inline-block;
    font-family: "GmarketB";
  }
`;

const MainBottomList = styled.div`
  width: 315px;
  margin: 0 auto;
  margin-top: 50px;
  overflow: hidden;
  & > header {
    text-align: center;
    font-size: 25px;
    line-height: 33px;
    letter-spacing: -0.75px;
    color: #0eefff;
    opacity: 1;
    font-family: "NanumSquareB";
  }
  & > div {
    transform: translateX(-100%);
    transition: all 0.4s ease-out;
    opacity: 0;
    transition-delay: 0s;

    ${(props) =>
      props.showCard &&
      css`
        transform: translateX(0);
        transition: all 0.45s ease-out;
        opacity: 1;
      `};
  }

  & > div:nth-child(2) {
    ${(props) =>
      props.showCard &&
      css`
        transition-delay: 0s;
      `};
  }
  & > div:nth-child(3) {
    ${(props) =>
      props.showCard &&
      css`
        transition-delay: 0.3s;
      `};
  }
  & > div:nth-child(4) {
    ${(props) =>
      props.showCard &&
      css`
        transition-delay: 0.6s;
      `};
  }
`;
const MainBottomCard = styled.div`
  border: 0.5px solid #ffffff;
  opacity: 1;
  width: 315px;
  height: 84px;
  position: relative;

  margin: 0 auto;

  margin-top: 15px;

  overflow: hidden;

  & > span {
    font-size: 23px;
    line-height: 22px;
    font-family: "GmarketB";

    letter-spacing: -0.92px;
    color: #ffffff;
    opacity: 1;

    position: absolute;
    left: 18px;
    top: 19px;
  }
  & > img {
    width: 92px;
    position: absolute;
    left: 52px;
    object-fit: cover;
    height: 84px;
  }
  & > div {
    text-align: right;
    letter-spacing: -0.68px;
    color: #ffffff;
    opacity: 1;

    font-size: 17px;
    line-height: 25px;
    position: absolute;
    right: 17px;
    top: 19px;
  }
  & > div > b {
    text-align: right;
    letter-spacing: -0.92px;
    color: #0eefff;

    font-size: 23px;
    line-height: 25px;
    font-family: "NanumSquareB";
  }
`;

const MainBottomHelpText = styled.div`
  margin-top: 20px;
  padding-bottom: 50px;
  text-align: center;

  font-size: 12px;
  line-height: 15px;

  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

const MainFooter = styled.div`
  height: 120px;
  background-color: #ffffff;
  text-align: center;
  color: #393939;
  letter-spacing: 0px;
  opacity: 1;

  & > b {
    font-size: 15px;
    line-height: 24px;

    display: block;
    padding-top: 35px;
    font-family: "NanumSquareB";
  }
  & > span {
    font-size: 11px;
    line-height: 24px;
  }
`;
