import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Button from "../components/common/Button";
import ShareButton from "../components/common/ShareButton";
import SubScribeForm from "../components/subscribe/SubScribeForm";
import loadingStage from "../asset/images/loadingstage.png";
import loadingQuestion from "../asset/images/loadingquestion.png";
import doubleQstart from "../asset/images/doubleQ_start.png";
import doubleQend from "../asset/images/doubleQ_end.png";
import { Helmet } from "react-helmet";
import result from "../modules/result";
import mainIcon from "../asset/images/letter_icon.png";
import { useHistory } from "react-router-dom";

const ResultPage = ({
  resultStar,
  loading,
  error,
  subscribe,
  mbti,
  showShare,
}) => {
  const [display, setDisplay] = useState(false);
  const timer = useRef(null);
  const history = useHistory();
  const [privacy, setPrivacy] = useState(false);
  let cntdown = 3;
  const downClock = () => {
    if (cntdown < 1) {
      return clearInterval(_loop);
    } else {
      cntdown--;
      if (loading && timer.current !== null) {
        timer.current.innerHTML = cntdown;
      }
    }
  };
  const _loop = setInterval(downClock, 1000);

  if (
    resultStar !== null &&
    (window.location.href === "https://www.billboardtest.co.kr/result" ||
      window.location.href === "http://www.billboardtest.co.kr/result" ||
      window.location.href === "https://billboardtest.co.kr/result" ||
      window.location.href === "http://billboardtest.co.kr/result" ||
      window.location.href === "http://127.0.0.1:3000/result" ||
      window.location.href === "http://localhost:3000/result")
  ) {
    history.replace(`/result/${resultStar.mbti}`);
  }

  if (error) {
    return <>잠시후에 다시 시도해주세요</>;
  }
  return loading && !mbti ? (
    <>
      <ResultContainer>
        <LoadingHeader>
          나와 닮은
          <div>빌보드 스타</div>
          <div> 분석 중...</div>
        </LoadingHeader>
        <LoadingBox>
          <img src={loadingStage} alt="로딩 무대 그림" />

          <img src={loadingQuestion} alt="로딩 물음표" />
        </LoadingBox>
        <LoadingCount>
          <span ref={timer}>{cntdown}</span>초 뒤에 결과가 나타납니다.
        </LoadingCount>
      </ResultContainer>
    </>
  ) : resultStar !== null ? (
    <>
      <Helmet title={"빌보드 테스트 - 결과"} />

      <ResultContainer>
        <ResultImageBox>
          <ResultImage
            src={"https://api.billboardtest.co.kr" + resultStar.image}
            alt={resultStar.name}
          />
        </ResultImageBox>

        <ResultHeader>
          <p>
            {resultStar.name === "레이디 가가" ||
            resultStar.name === "마돈나" ||
            resultStar.name === "셀레나 고메즈" ||
            resultStar.name === "샘 스미스" ||
            resultStar.name === "프레디 머큐리" ||
            resultStar.name === "켄드릭 라마" ||
            resultStar.name === "드레이크" ||
            resultStar.name === "테일러 스위프트" ||
            resultStar.name === "저스틴 비버" ||
            resultStar.name === "빌리 아일리시" ||
            resultStar.name === "리아나" ||
            resultStar.name === "아리아나 그란데"
              ? resultStar.name + "를 "
              : resultStar.name + "을 "}
            닮은 당신은
          </p>
          <span>{resultStar.sub_title}</span>
        </ResultHeader>
        <ResultExplain>{resultStar.explain}</ResultExplain>

        <ResultSection>
          <header>평소에 당신은?</header>
          {resultStar.tendency.split("/").map((item) => (
            <li>
              <span>•</span> {item}
            </li>
          ))}
        </ResultSection>

        <ResultSection bottomMargin>
          <header>
            실제로
            {resultStar.name === "레이디 가가" ||
            resultStar.name === "마돈나" ||
            resultStar.name === "셀레나 고메즈" ||
            resultStar.name === "샘 스미스" ||
            resultStar.name === "프레디 머큐리" ||
            resultStar.name === "켄드릭 라마" ||
            resultStar.name === "드레이크" ||
            resultStar.name === "테일러 스위프트" ||
            resultStar.name === "저스틴 비버" ||
            resultStar.name === "빌리 아일리시" ||
            resultStar.name === "리아나" ||
            resultStar.name === "아리아나 그란데"
              ? " " + resultStar.name + "는"
              : " " + resultStar.name + "은"}
          </header>

          <div>
            <img src={doubleQstart} alt="doubleQstart" />
            <p>
              {resultStar.actually.split("/").map((line) => {
                return <span>{line}</span>;
              })}
            </p>
            <ResultSectionTail />

            <img src={doubleQend} alt="doubleQend" />
          </div>
        </ResultSection>

        <FriendBox>
          <ResultFriend>
            <FriendImageBox>
              <img
                src={
                  "https://api.billboardtest.co.kr" + resultStar.friend.image
                }
                alt={resultStar.friend.name}
              />
            </FriendImageBox>
            <FriendContent>
              <b>최고의 친구</b>
              <p>{resultStar.friend.sub_title} </p>
              <span>{resultStar.friend.name}</span>
            </FriendContent>
          </ResultFriend>
          <ResultFriend>
            <FriendImageBox>
              <img
                src={"https://api.billboardtest.co.kr" + resultStar.enemy.image}
                alt={resultStar.enemy.name}
              />
            </FriendImageBox>
            <FriendContent>
              <b>어색한 친구</b>
              <p>{resultStar.enemy.sub_title}</p>
              <span>{resultStar.enemy.name}</span>
            </FriendContent>
          </ResultFriend>
        </FriendBox>

        <SubScribeBlock>
          <div>
            <header>
              {resultStar.forNewsLetterTop}
              <br />
              <b>이유식 뉴스레터💌</b>
            </header>
            <p>
              {resultStar.forNewsLetterBottom.split("/").map((line) => {
                return <span>{line}</span>;
              })}
            </p>
            <SubScribeForm display={display} subscribe={subscribe} />

            <SubScribeButton
              display={display}
              fullwidth
              onClick={() => setDisplay(!display)}
            >
              <img src={mainIcon} alt="mainIcon" /> <span>받고 싶어요</span>
            </SubScribeButton>
          </div>

          <ReturnButton fullwidth to="/">
            <span>테스트 다시하기</span>
          </ReturnButton>
          <PrivacyText onClick={() => setPrivacy(!privacy)}>
            개인정보취급방침
          </PrivacyText>
          <PrivacyContent privacy={privacy}>
            [개인정보취급방침]
            <br /> <br />
            1) 수집하는 개인정보 항목 <br /> <br />
            회사는 뉴스레터 구독 접수를 위해 위와같은 개인정보를 수집하고
            있습니다. <br />
            - 수집항목 : 이메일 주소 <br />
            - 개인정보 수집방법 : 유니버셜 뮤직 뉴스레터 구독 Form 제출 <br />{" "}
            <br /> <br />
            2) 개인정보의 수집 및 이용목적 <br /> <br />
            회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. <br />{" "}
            -서비스 제공에 따른 정보제공, 콘텐츠 제공(뉴스레터 제공)에 활용
            <br /> <br />
            3) 개인정보 보유 및 이용기간 <br />
            -원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
            지체없이 파기합니다. <br />
            -회사는 이용자의 개인정보를 DB에 보유,저장하고 있지 않습니다. <br />{" "}
            <br /> <br />
            4) 개인정보 제공 <br />
            회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다.
            다만, 아래의 경우에는 예외로 합니다. <br /> <br />
            - 개인정보를 제공받는 자 : 스티비(https://www.stibee.com/) <br />-
            제공받는 자의 개인정보 이용목적 : 이메일을 통한 뉴스레터 송신 <br />
            - 제공받는 자의 보유.이용기간: 1년 <br />
            - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
            방법에 따라 수사기관의 요구가 있는 경우 <br />
            <br /> <br />
            5) 개인정보처리 위탁 <br /> <br />
             회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무
            수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한,
            수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등
            문서에 명시하고, <br />
            수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
            <br /> <br />
            위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
            처리방침을 통하여 공개하도록 하겠습니다. <br />
            이용자 및 법정대리인의 권리와 그 행사방법 이용자들의 개인정보 조회,
            수정을 위해서는 본인 확인 절차를 거치신 후 정정 또는 탈퇴가
            가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로
            연락하시면 지체없이 조치하겠습니다. <br />
            <br />
            귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
            완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
            잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
            제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다. <br />
            회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가
            수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그
            외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            <br /> <br />
            6) 개인정보에 관한 민원서비스 <br />
            회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
            위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고
            있습니다. <br /> <br />
            - 고객서비스담당 부서 : Music & Brands <br />
            - 전화번호 : 02-2106-2000 <br />
            - 이메일 : info.kr@umusic.com <br /> <br />
            귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
            관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.{" "}
            <br />
            회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴
            것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
            아래 기관에 문의하시기 바랍니다. <br /> <br />
            - 개인분쟁조정위원회 <br />
            (www.1336.or.kr/1336) - 정보보호마크인증위원회 <br />
            (www.eprivacy.or.kr/02-580-0533~4) - 대검찰청 인터넷범죄수사센터{" "}
            <br />
            (http://icic.sppo.go.kr/02-3480-3600) - 경찰청 사이버테러대응센터{" "}
            <br />
          </PrivacyContent>
        </SubScribeBlock>

        <ShareButtonBox showShare={showShare}>
          <ShareButton star={resultStar} type="default" />
          <ShareButton star={resultStar} type="kakao" />
        </ShareButtonBox>
      </ResultContainer>
    </>
  ) : (
    <>{null}</>
  );
};

export default ResultPage;

const ResultContainer = styled.div`
  position: relative;

  overflow: hidden;
`;
const LoadingHeader = styled.header`
  margin: 0 auto;

  margin-top: 92px;

  letter-spacing: -1.35px;
  color: #ffffff;
  opacity: 1;

  font-size: 27px;
  line-height: 40px;
  font-weight: 300;
  font-family: "NanumSquareL";

  text-align: center;

  & > div {
    font: normal normal bold 40px/39px NanumSquareOTF;
    letter-spacing: -1.6px;
    color: #0eefff;
    font-size: 40px;
    line-height: 39px;

    font-weight: bold;
    font-family: "NanumSquare";
  }
  & > div + div {
    margin-top: 17px;
  }
`;

const LoadingBox = styled.div`
  @keyframes moveQuestion {
    0% {
      padding-top: 102px;
      transform: rotate3d(0, 1, 0, 0deg);
    }
    20% {
      padding-top: 82px;
      transform: rotate3d(0, 1, 0, 30deg);
    }
    40% {
      padding-top: 102px;
      transform: rotate3d(0, 1, 0, 60deg);
    }
    60% {
      padding-top: 82px;
      transform: rotate3d(0, 1, 0, 90deg);
    }
    80% {
      padding-top: 102px;
      transform: rotate3d(0, 1, 0, 120deg);
    }
    100% {
      padding-top: 82px;
      transform: rotate3d(0, 1, 0, 150deg);
    }
  }
  position: relative;
  width: 100%;
  margin-top: 56px;
  height: 200px;

  & > img:first-child {
    position: absolute;
    left: calc(50% - 97px);
    top: 0;
    width: 194px;
    z-index: -1;
  }
  & > img:last-child {
    display: block;
    margin: 0 auto;
    padding-top: 102px;
    /* animation-duration: 1.5s;
    animation-name: moveQuestion;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0s;
    animation-timing-function: linear; */
    animation: moveQuestion 1.5s linear 0s infinite alternate;
  }
`;

const LoadingCount = styled.div`
  margin-top: 110px;

  font-size: 15px;
  line-height: 25px;

  text-align: center;
  letter-spacing: -0.6px;
  color: #ffffff;
  opacity: 1;
`;
const ResultImageBox = styled.div`
  max-width: 375px;
  margin: 0 auto;
  margin-top: 46px;

  width: 254px;
  height: 254px;

  border-radius: 50%;

  border: 2px solid #0eefff;
  overflow: hidden;
`;
const ResultImage = styled.img`
  height: 100%;
`;

const ResultHeader = styled.header`
  max-width: 375px;
  padding: 0 30px;
  margin: 0 auto;
  margin-top: 34px;

  & > p {
    letter-spacing: -0.88px;
    color: #ffffff;
    opacity: 1;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;

    text-align: center;
    margin: 0;
  }

  & > span {
    display: block;
    text-align: center;
    margin: 0 auto;
    letter-spacing: -1.32px;
    color: #0eefff;
    opacity: 1;
    font-size: 33px;
    line-height: 54px;

    font-family: "NanumSquareEB";
  }
`;

const ResultExplain = styled.p`
  max-width: 375px;
  padding: 0 30px;
  margin: 0 auto;

  text-align: left;
  letter-spacing: -0.64px;
  color: #ffffff;
  opacity: 1;

  font-size: 16px;
  line-height: 28px;

  margin-top: 26px;
  margin-bottom: 50px;
`;

const ResultSection = styled.section`
  max-width: 375px;
  padding: 0 30px;
  margin: 0 auto;
  & + & {
    margin-top: 68px;
  }
  /* 여기는 평소에 당신은? */

  ${(props) =>
    props.bottomMargin &&
    css`
      margin-bottom: 70px;
    `}
  & > header {
    letter-spacing: -0.88px;
    color: #0eefff;
    opacity: 1;

    font-size: 22px;
    line-height: 25px;

    padding-left: 10px;
    margin-bottom: 17px;
    font-family: "NanumSquareEB";
  }
  & > li {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;

    list-style: none;

    padding: 8px 11px;

    font-size: 15px;
    line-height: 25px;

    letter-spacing: -0.6px;
    color: #191619;
    opacity: 1;
  }
  & > li + li {
    margin-top: 9px;
  }

  /* 아래는 정말 비슷하다구?  */
  & > div {
    background: #0eefff 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    padding: 40px 22px;

    text-align: center;

    font-size: 15px;
    line-height: 24px;

    letter-spacing: -0.6px;
    color: #191619;

    position: relative;
  }
  & > div > p {
    span + span::before {
      content: "";
      display: block;
    }
  }
  & > div > img {
    position: absolute;
  }
  & > div > img:first-child {
    width: 23px;
    top: 18px;
    left: 22px;
  }
  & > div > img:last-child {
    width: 29px;
    bottom: 16px;
    right: 22px;
  }
`;

const ResultSectionTail = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  right: 50px;
  bottom: -20px;
  border-top: 20px solid #0eefff;
`;
const FriendBox = styled.div`
  max-width: 375px;
  padding: 0 30px;
  margin: 0 auto;
`;
const ResultFriend = styled.div`
  max-width: 375px;
  padding: 0 30px;
  margin: 0 auto;

  display: flex;
  background-color: #3c3e44;
  padding: 20px 0;
  height: 132px;
  width: 375px;
  margin-left: -30px;
  & + & {
    margin-top: 10px;
  }
`;
const FriendImageBox = styled.div`
  width: 93px;
  height: 93px;

  margin-left: 30px;
  border-radius: 50%;

  background-color: #ffffff;

  border: 2px solid #0eefff;
  opacity: 1;

  overflow: hidden;
  margin-right: 17px;

  & > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const FriendContent = styled.div`
  & > b {
    text-align: left;
    letter-spacing: -1px;
    color: #0eefff;
    opacity: 1;

    font-size: 25px;
    line-height: 38px;

    display: inline-block;
    font-family: "NanumSquareB";
  }
  & > p {
    text-align: left;
    letter-spacing: -0.76px;
    color: #ffffff;
    opacity: 1;

    font-size: 19px;
    line-height: 29px;

    margin: 0;
  }

  & > span {
    display: block;
    letter-spacing: -0.88px;
    color: #ffffff;
    opacity: 1;

    font-size: 22px;
    font-weight: 33px;
    font-family: "NanumSquareB";
  }
`;

const SubScribeBlock = styled.section`
  background-color: #f3f3f3;
  color: #191619;
  text-align: center;
  padding: 0 30px;
  padding-top: 96px;
  padding-bottom: 82px;
  width: 100%;
  & > div {
    max-width: 375px;
    margin: 0 auto;
  }
  & > div > header {
    font-size: 22px;
    line-height: 38px;
    font: normal normal 800 22px/38px NanumSquareOTF;
    letter-spacing: -0.88px;

    font-family: "NanumSquareEB";
  }
  & > div > header > b {
    font-size: 30px;
    letter-spacing: -1.2px;
  }
  & > div > p {
    letter-spacing: -0.54px;
    color: #3c3e44;
    opacity: 1;

    font-size: 18px;
    line-height: 26px;
    margin: 0 auto;
    margin-top: 27px;
    word-break: keep-all;

    span + span::before {
      content: "";
      display: block;
    }
  }
`;

const SubScribeButton = styled(Button)`
  margin: 0 auto;
  margin-top: 39px;

  background: #191619 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  padding: 10px;

  font-family: "NanumSquare";
  height: 54px;
  font-weight: bold;
  text-align: center;

  font-size: 27px;
  line-height: 19px;
  letter-spacing: -0.9px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 38px;
    height: 23px;

    margin-right: 12px;
  }
  ${(props) =>
    props.display &&
    css`
      color: none;
      height: 0px;
      opacity: 0;
      padding: 0;
      margin: 0;
      max-height: max-content;
      transition: all 0.3s ease-out;
    `};
`;

const ShareButtonBox = styled.span`
  & > button {
    transform: scale(0);
    opacity: 0;

    transition: all 0.4s ease-out;
    pointer-events: none;

    ${(props) =>
      props.showShare &&
      css`
        transform: scale(1);
        transition: all 0.4s ease-out;
        opacity: 1;

        pointer-events: unset;
      `};
  }
`;

const ReturnButton = styled(Button)`
  max-width: 375px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #f3f3f3;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(50, 50, 50, 0.8);
  -moz-box-shadow: 1px 1px 10px 0px rgba(50, 50, 50, 0.8);
  box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.3);
  border-radius: 15px;
  opacity: 1;
  padding: 10px;

  font-family: "NanumSquare";
  height: 54px;
  font-weight: bold;
  text-align: center;

  font-size: 27px;
  line-height: 19px;
  letter-spacing: -0.9px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
`;

const PrivacyText = styled.div`
  font-size: 16px;
  margin-top: 50px !important;
  cursor: pointer;
  color: #888;
  letter-spacing: -0.9px;
`;

const PrivacyContent = styled.div`
  font-size: 12px;
  color: #888;

  margin-top: 20px !important;
  height: 0;
  overflow-y: scroll;
  border-radius: 5px;
  text-align: left;
  word-break: keep-all;
  line-height: 1.5;
  transition: all 0.2s ease-out;
  ${(props) =>
    props.privacy &&
    css`
      height: 150px;
      transition: all 0.2s ease-out;
    `};
`;
