import { createAction, handleActions } from "redux-actions";

// Saga를 만드는 부분을 따로 lib로 만들어서 빼서 관리한다.
import createRequestSaga, {
  createRequestActionTypes,
} from "../lib/createRequestSaga";

import { takeLatest } from "redux-saga/effects";

import * as quizApi from "../lib/api/quiz";
const [GETQUIZ, GETQUIZ_SUCCESS, GETQUIZ_FAILURE] = createRequestActionTypes(
  "quiz/GETQUIZ"
);

export const getQuiz = createAction(GETQUIZ);

const getQuizSaga = createRequestSaga(GETQUIZ, quizApi.getQuizList);

export function* quizSaga() {
  yield takeLatest(GETQUIZ, getQuizSaga);
}

const initialState = {
  quizList: [],
  resultStar: null,
  error: null,
};

const quiz = handleActions(
  {
    [GETQUIZ_SUCCESS]: (state, { payload: quizs }) => {
      return {
        ...state,
        quizList: quizs.quizs,
      };
    },

    [GETQUIZ_FAILURE]: (state, { payload: error }) => {
      return {
        ...state,
        error,
      };
    },
  },
  initialState
);

export default quiz;
