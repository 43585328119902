import { combineReducers } from "redux";
import intro, { introSaga } from "./intro";
import quiz, { quizSaga } from "./quiz";
import result, { resultSaga } from "./result";
import loading from "./loading";
// 아래는 react saga와 관련된 root reducer세팅 부분
import { all } from "redux-saga/effects";

const rootReducer = combineReducers({
  loading,
  intro,
  quiz,
  result,
});

export function* rootSaga() {
  yield all([introSaga(), quizSaga(), resultSaga()]);
}

export default rootReducer;
