import React, { useEffect, useState } from "react";
import MainPage from "../pages/MainPage";
import { useDispatch, useSelector } from "react-redux";
import { getIntro } from "../modules/intro";

import { iOS } from "../lib/checkIOS";
const MainPageContainer = () => {
  const dispatch = useDispatch();
  const [showCard, setShowCard] = useState(false);
  const { total_cnt, loading, error, top_three_star } = useSelector(
    ({ intro, loading }) => ({
      total_cnt: intro.total_cnt,
      loading: loading["intro/GETINTRO"],
      error: intro.error,
      top_three_star: intro.top_three_star,
    })
  );

  useEffect(() => {
    dispatch(getIntro());
    let flag = false;
    window.addEventListener("scroll", function (e) {
      // console.log(e.path[7].scrollY, "gggg");
      if (this.pageYOffset > 100 && flag === false) {
        flag = true;
        setShowCard(true);
        // console.log("카드 시그널");
      }

      if (this.pageYOffset <= 100 && flag === true) {
        flag = false;
        setShowCard(false);
        // console.log("숨김 시그너ㅏㄹ");
      }
    });

    return () => {};
  }, [dispatch]);

  return (
    <MainPage
      total_cnt={total_cnt}
      loading={loading}
      error={error}
      top_three_star={top_three_star}
      showCard={showCard}
    />
  );
};

export default MainPageContainer;
