export const kakaoScript = () => {
  const kakaoscript = document.createElement("script");
  kakaoscript.src = "https://developers.kakao.com/sdk/js/kakao.js";
  document.head.appendChild(kakaoscript);
};

export const kakaoInit = () => {
  const tryInit = setInterval(() => {
    if (window.Kakao !== undefined) {
      if (window.Kakao.isInitialized() === true) {
        return clearInterval(tryInit);
      }
      if (window.Kakao !== undefined) {
        window.Kakao.init("ebb0a4124111282614880e95e5453222");
      }
    }
  }, 1000);
};
